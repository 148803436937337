*,
:after,
:before {
  box-sizing: border-box;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:first-child,
.recharts-wrapper .recharts-cartesian-grid-vertical line:last-child {
  stroke-opacity: 0 !important;
}

.timeline-dot.pulsating-circle {
  transform: translate(8.5px, 0);
}

.pulsating-circle:before {
  content: '';
  position: relative;
  display: block;
  width: 300%;
  height: 300%;
  box-sizing: border-box;
  margin-left: -100%;
  margin-top: -100%;
  border-radius: 45px;
  background-color: #ff5c00;
  animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

.pulsating-circle:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 92, 0, 0.1);
  border-radius: 15px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
}

main {
  background-color: #fafafa;
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

/* React toastify styles */

.Toastify__toast {
  border-radius: 0 !important;
  padding: 12px 16px !important;
  box-shadow: 0px 4px 8px 0px rgba(48, 85, 159, 0.1);
  display: flex;
  align-items: center;
  font-family: inherit;
}

.Toastify__toast-icon {
  margin-right: 12px !important;
}

.Toastify__toast-body {
  padding: 0 !important;
}

.Toastify__toast-container--bottom-right {
  right: 0;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #3d8556;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #e20202;
}

@media (min-width: 480px) {
  /* 480px is a default breakpoint used by the react-toastify to apply styles for mobile devices   */
  .Toastify__toast-container {
    width: 450px !important;
  }
  .Toastify__toast-container.Toastify__toast-container--bottom-right {
    width: 320px !important;
  }
}

@media (max-width: 480px) {
  .Toastify__toast-container.Toastify__toast-container--bottom-right {
    width: 320px !important;
    bottom: 1em;
    left: unset;
  }
  .Toastify__toast {
    margin: 1em !important;
  }
}

@media (min-width: 480px) {
  /* 480px is a default breakpoint used by the react-toastify to apply styles for mobile devices   */
  .Toastify__toast-container {
    width: 450px !important;
  }
  .Toastify__toast-container.Toastify__toast-container--bottom-right {
    width: 320px !important;
  }
}

@media (max-width: 480px) {
  .Toastify__toast-container.Toastify__toast-container--bottom-right {
    width: 320px !important;
    bottom: 1em;
    left: unset;
  }
  .Toastify__toast {
    margin: 1em !important;
  }
}

#nprogress::after {
  content: '';
  background-color: #FFC09C;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 2px;
  z-index: 1500;
}



